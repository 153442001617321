export default [
    { headerName: 'ID', field: 'id' },
    { headerName: 'LEA', field: 'lea', initialHide: true, filter: false },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    { headerName: 'Update Date', field: 'updateDate' },
    {
        headerName: 'Student ID',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    { headerName: 'Is Deleted', field: 'isDeleted', filter: false },
    { headerName: 'Due Date', field: 'dueDate', filter: false },
    { headerName: 'Course Section ID', field: 'courseSectionId', filter: false },
    { headerName: 'Grade Period ID', field: 'gradingPeriodId', filter: false },
    { headerName: 'Marking Period', field: 'markingPeriod', filter: false },
    { headerName: 'Assignment ID', field: 'assignmentId', filter: false },
    {
        headerName: 'Assignment Category ID',
        field: 'assignmentCategoryName',
        filter: false,
    },
    { headerName: 'Assignment Name', field: 'assignmentName', filter: false },
    {
        headerName: 'Assignment Grade Letter',
        field: 'assignmentGradeLetter',
        filter: false,
    },
    {
        headerName: 'Assignment Grade Percentage',
        field: 'assignmentGradePercentage',
        filter: false,
    },
]
